globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"1fc6ad67662214327c2d370404fa7e84e6ed2eab"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: `https://060f7a9a5b521fa8049a468715994d34@o4506519509991424.ingest.sentry.io/4506519511367680`,
  normalizeDepth: 3,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1,

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
  enabled: process.env.NEXT_PUBLIC_ENV_PROFILE === `production`,

  replaysOnErrorSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  ignoreErrors: [
    `AxiosError`,
    `ER_TOO_MANY_USER_CONNECTIONS`,
    `Network Error`,
    `common.json`,
    `Request failed with status code 512`,
  ],
  // You can remove this option if you're not planning to use the Sentry Session Replay feature:
  integrations: [],
});
